import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { replaceLinks, UsedLinks } from '../helpers.ts'
import GlobalContext from './GlobalContext.tsx'
import MyMarkdown from './Markdown.tsx'
import { ResultSearchZueriEntry, SearchResult } from './ResultSearchZueriEntry.tsx'
const host = window.location.hostname.includes('localhost') ? 'http://localhost:3000' : 'https://wingpt-api.gpt.liip.ch'

export const ResultSearchZueri = (props: { query: string | null; userId: string | null; disabled?: boolean }) => {
  const [sseUri, setSseUri] = useState('')
  const [searchResult, setSearchResult] = useState<SearchResult[]>([])
  const { t } = useTranslation()
  const [functionResult, setFunctionResult] = useState<string>('')
  const { mainLanguage, org } = useContext(GlobalContext)
  const [usedLinksResult, setUsedLinksResult] = useState<UsedLinks[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (props.query && !(props.disabled === true)) {
      setSseUri(
        `${host}/wingpt/search?query=${encodeURIComponent(`${props.query}`)}&key=X9hL4Gp5W2D7eRtF&r=${Math.random()}${
          props.userId ? `&uID=${props.userId}` : ''
        }&lang=${mainLanguage}`,
      )
    }
  }, [mainLanguage, org, props.query, props.userId, props.disabled])

  useEffect(() => {
    try {
      if (props.disabled === true) {
        return
      }
      if (!sseUri) {
        return
      }
      setUsedLinksResult([])
      setFunctionResult('')
      setIsLoading(true)
      setFunctionResult(t('answers.oneMoment'))
      setSearchResult([])
      fetch(sseUri, {
        method: 'get',

        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(async response => {
          const messages = await response.json()
          if (Array.isArray(messages)) {
            if (messages.length === 0) {
              setFunctionResult('Leider keine Seiten gefunden')
            } else {
              setFunctionResult('   ')
            }
            setSearchResult(messages)
          }
          setIsLoading(false)
        })
        .catch(error => {
          console.log('Messages error', error)

          setSearchResult([])
        })
    } catch (error) {
      console.log(error)
    }
  }, [props.disabled, sseUri, t])
  if (!functionResult) {
    return null
  }
  const title = 'Suchresultat von stadt.winterthur.ch'

  return (
    <div>
      <h1>{title}</h1>

      <div className="resultBox zueriSearchResult" translate="no">
        <ResultSearchZueriEntry entries={searchResult} urlPrefix={''} />
        {searchResult.length > 0 && <hr />}
        <MyMarkdown
          markdown={
            replaceLinks(functionResult, usedLinksResult) +
            (isLoading
              ? ' ![](https://chregus.rokka.io/dynamic/crop-width-200-height-25--resize-width-20/o-af-1/b9a927/circles-menu-1.gif)'
              : '')
          }
          usedLinks={usedLinksResult}
        ></MyMarkdown>
      </div>
    </div>
  )
}
